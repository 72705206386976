import React from "react"
import { Row, Col } from "react-bootstrap"

const ImageDownloadRow = ({
    root,
    filename,
    name,
    text,
    hasSquare,
    hasStory,
    href,
}) => {
    return (
        <Row className="mt-5">
            <Col md={6}>
                <img
                    src={`${root}/${filename}.png`}
                    className="img-fluid"
                    alt=""
                    style={{ maxHeight: "350px" }}
                />
            </Col>
            <Col>
                <h3>{name}</h3>
                <p className="para" style={{ whiteSpace: "pre-wrap" }}>
                    {text}
                </p>
                {!href ? (

                    <a
                    href={`${root}/${filename}.png`}
                    download={`${name}.png`}
                    className="btn btn-primary m-3"
                    >
                    Download Image
                </a>
                    ) : (
                        <a href={href} className="btn btn-primary m-3" target="_blank">Use Template</a>
                    )}
                {hasSquare && (
                    <a
                        href={`${root}/${filename}-square.png`}
                        download={`${name}-square.png`}
                        className="btn btn-primary m-3"
                    >
                        Download Square Image
                    </a>
                )}
                {hasStory && (
                    <a
                        href={`${root}/${filename}-story.png`}
                        download={`${name}-story.png`}
                        className="btn btn-primary m-3"
                    >
                        Download Story Image
                    </a>
                )}
            </Col>
        </Row>
    )
}

export default ImageDownloadRow
