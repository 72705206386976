import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageLinkRow from "../../components/ImageLinkRow"
import ImageDownloadRow from "../../components/ImageDownloadRow"


const IndexPage = () => {
    const root = "/img/download/leaders/digital"

    return (
        <Layout pageTitle="Leaders - Badges">
            <Row>
                <Col>
                    <Link to="/leaders">Back</Link>
                </Col>
            </Row>
            
            <Row className="mt-5 mb-5">
                <Col>
                    <h2>Badges</h2>
                    <p className="para">
                        Download appropriate badge to display on your website,
                        email footer, business cars etc. 
                    </p>
                </Col>
            </Row>
            <ImageDownloadRow
                root={root}
                name="Leader Badge"
                filename="leader-badge"
            />
            <ImageDownloadRow
                root={root}
                name="Mentor Badge"
                filename="mentor-badge"
            />
            <ImageDownloadRow
                root={root}
                name="Member Badge"
                filename="member-badge"
            />
            
            
        </Layout>
    )
}

export default IndexPage
