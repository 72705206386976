import React from "react"
import { Row, Col } from "react-bootstrap"

const ImageLinkRow = ({ image, name, text, url }) => {
    return (
        <Row className="mt-5">
            <Col md={6}>
                <img
                    src={image}
                    className="img-fluid"
                    alt=""
                    style={{ maxHeight: "350px" }}
                />
            </Col>
            <Col>
                <h3>{name}</h3>
                <p className="para" style={{ whiteSpace: "pre-wrap" }}>
                    {text}
                </p>
                <a
                    href={url}
                    className="btn btn-primary m-3"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    View Template
                </a>
            </Col>
        </Row>
    )
}

export default ImageLinkRow
